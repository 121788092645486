import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { API } from 'aws-amplify';
import ReportTable from './ReportTable'


import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import {CSVLink} from "react-csv";

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersList: [],
            accounts: [],
            accountsList: [],
            report: [],
            user_group : null,
            selectedAction: "addAccess",
            selectedUser: "All",
            selectedUserGroup: null,
            selectedAccountGroup: null,
            selectedAccount: "All",
            account_group: null,
            show : false,
            isLoading : false,
            isError : false,
            getUserStatus : null,
            getAccountStatus : null,
            getReportStatus:null,
            removeAccessStatus:null,
            errors: {}
        };
    }
    handleLoading = () => {
        this.setState({isLoading:!this.state.isLoading})
    }

    handleError = () => {
        this.setState({isError:!this.state.isError})
    }

    getUserDict = (user) => {
        var dict = {value : user.gid, label : user.gid.concat(" - ",user.email)} 

        return dict;
    }

    getDict = (item) => {
        var dict = {value : item, label : item} 
        return dict;
    }

    getAccountDict = (account) => {
        var dict = {value : account.account_id, label : account.account_id.concat(" - ",account.account_name)}
        return dict;
    }

    handleUserGroupChange = async(usergroup) => {

        this.handleLoading()

        this.setState({selectedUserGroup:usergroup})
        this.setState({selectedUser:"All"})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:"All"})
        this.setState({users:null})
        this.setState({usersList:null})
        this.setState({accounts:null})
        this.setState({accountsList:null})

        let apiName = 'awstvm';
        let path = '/dev/users';
        
        let myInit = { 
            queryStringParameters: {
            user_group: usergroup.value
          }

        }
        let usersList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({users : response});
                usersList = response.map(this.getUserDict)
                this.setState({usersList : usersList})
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getUserStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleAccountGroupChange = async(accountgroup) => {
        this.handleLoading()

        this.setState({selectedAccountGroup:accountgroup})
        this.setState({selectedAccount:"All"})
        this.setState({accounts:null})
        this.setState({accountsList:null})

        let apiName = 'awstvm';
        let path = '/dev/accounts';

        if (this.state.selectedUser.value === "All")
        {
            var myInit = { 
                queryStringParameters: {
                account_group: accountgroup.value
            }
            }
        }
        else
        {
             myInit = {
                queryStringParameters: {
                account_group: accountgroup.value,
                gid: this.state.selectedUser.value
            }
            }
        }
        let accountsList = [];

        await API.get(apiName, path, myInit).then(
            response => {
                this.setState({accounts : response});
                accountsList = response.map(this.getAccountDict)
                this.setState({accountsList : accountsList})
                this.handleLoading()
            }
            )
        .catch(error => {
            console.log(error)
            if(error)
                if(error.response)
                if(error.response.status)
                    this.setState({getAccountStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
            )
    }

    handleAccountChange = async(account_id) => {
        this.setState({selectedAccount:account_id})
    }

    handleUserChange = async(user) => {
        this.setState({selectedUser: user})
    }

    handleActionChange = async(action) => {

        this.setState({selectedAction:action})
        this.setState({selectedUser:null})
        this.setState({selectedUserGroup:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
        this.setState({users:null})
        this.setState({usersList:null})
        this.setState({errors:{}})
    }
    validate(){
        let errors = {};
        let isValid = true;

        if(!this.state.selectedUserGroup)
        {
            isValid = false;
            errors["userGroup"] = "Please select the user group.";
        }

        if(!this.state.selectedAccountGroup)
        {
            isValid = false;
            errors["accountGroup"] = "Please select the account group.";
        }
        this.setState({
            errors: errors
          });
        
        return isValid
    }


    handleSubmit = async(event) => {

        event.preventDefault();

        if(this.validate()){

            this.handleLoading()
            this.setState({errors:{}})
            this.setState({report:[]})

            let apiName = 'awstvm';
            let path = '/dev/report';

            let myInit = {
                queryStringParameters: {
                }
            }

            if (this.state.selectedUser !== null && this.state.selectedAccount !== null)
            {
                if (this.state.selectedUser.value !== 'All' && this.state.selectedAccount.value !== 'All')
                {
                    myInit = {
                        queryStringParameters: {
                            'account_id': this.state.selectedAccount.value,
                            'gid': this.state.selectedUser.value
                        }
                    }
                }
                else if (this.state.selectedUser.value === 'All' && this.state.selectedAccount.value !== 'All')
                {
                    myInit = {
                        queryStringParameters: {
                            'account_id': this.state.selectedAccount.value
                        }
                    }
                }
                else if (this.state.selectedUser.value !== 'All' && this.state.selectedAccount.value === 'All')
                {
                    myInit = {
                        queryStringParameters: {
                            'gid': this.state.selectedUser.value
                        }
                    }
                }

            }
            else if (this.state.selectedUser === null && this.state.selectedAccount !== null)
            {
                if (this.state.selectedAccount.value !== 'All')
                {
                    myInit = {
                        queryStringParameters: {
                            'account_id': this.state.selectedAccount.value
                        }
                    }
                }
                
            }
            else if (this.state.selectedUser !== null && this.state.selectedAccount === null)
            {
                if (this.state.selectedUser.value !== 'All')
                {
                    myInit = {
                        queryStringParameters: {
                            'gid': this.state.selectedUser.value
                        }
                    }
                }
                
            }
            console.log("IN API")
            console.log(myInit)
            let report = [];
            await API.get(apiName, path, myInit).then(
                response => {
                    this.setState({getReportStatus : 200});
                    report = response
                    this.setState({report : report})
                    this.handleLoading()
                }
                )
            .catch(error => {
                console.log(error)
                if(error)
                    if(error.response)
                    if(error.response.status)
                        this.setState({getReportStatus: error.response.status});
                this.handleLoading()
                this.handleError()
                }
            )            
    }
    }


    resetAlert = () => {
        console.log("In Reset Alert")
        this.setState({getReportStatus:null})
        this.setState({removeAccessStatus:null})
        this.setState({selectedUser:null})
        this.setState({selectedUserGroup:null})
        this.setState({selectedAccountGroup:null})
        this.setState({selectedAccount:null})
    }

    render() {
        const {accounts_group, users_group} = this.props;
        const { report,  selectedUser, selectedUserGroup, selectedAccountGroup, selectedAccount, usersList, accountsList, errors } = this.state;
        const all = [{value : "All", label : "All"}]
        let users_groupList = []
        users_groupList = users_group.map(this.getDict)
        let account_groupList = []
        account_groupList = accounts_group.map(this.getDict)

        let usersList_all = all.concat(usersList)
        let accountsList_all = all.concat(accountsList)

        const headers = [
            { label: "gid", key: "gid" },
            { label: "Name", key: "name" },
            { label: "Email", key: "email" },
            { label: "Account Name", key: "account_name" },
            { label: "Account ID", key: "account_id" },
            { label: "Role", key: "role_name" }
          ];

        return (
            <div>
            <Form onSubmit={this.handleSubmit}>
            <div>
                <Modal
                  show={this.state.isLoading}
                  onHide={this.handleLoading}
                  size="sm"
                  dialogClassName="modal-5w"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  >
                 <Modal.Body>
                 <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                 </Modal.Body>
               </Modal>
            </div>
                <br />
                <br />
                <br />
                <br />
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    UserGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="usergroup-select"
                        autoFocus
                        options={users_groupList}
                        simpleValue
                        clearable={true}
                        name="selected-usergroup"
                        value={selectedUserGroup}
                        onChange={this.handleUserGroupChange}
                    />
                    <div className="text-danger">{errors.userGroup}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="user">
                    <Form.Label column sm={2}>
                    User
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="user-select"
                        autoFocus
                        placeholder="All"
                        options={usersList_all}
                        simpleValue
                        name="selected-user"
                        value={selectedUser}
                        onChange={this.handleUserChange}
                    />
                    <div className="text-danger">{errors.user}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="accountgroup">
                    <Form.Label column sm={2}>
                    AccountGroup
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="accountgroup-select"
                        autoFocus
                        options={account_groupList}
                        simpleValue
                        name="selected-accountgroup"
                        value={selectedAccountGroup}
                        onChange={this.handleAccountGroupChange}
                    />
                    <div className="text-danger">{errors.accountGroup}</div>
                    </Col>
                </Form.Group>
   
                <Form.Group as={Row} controlId="accountid">
                    <Form.Label column sm={2}>
                    accountid
                    </Form.Label>
                    <Col sm={10}>
                    <Select
                        id="account-select"
                        autoFocus
                        placeholder="All"
                        options={accountsList_all}
                        simpleValue
                        name="selected-account"
                        value={selectedAccount}
                        onChange={this.handleAccountChange}
                    />
                    <div className="text-danger">{errors.account}</div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit">Submit</Button>
                    </Col>
                </Form.Group>
                </Form>
                <CSVLink headers={headers} data={report} filename="AWS-access.csv">
                    <button type="submit">DownLoad CSV</button>
                </CSVLink>
                <ReportTable data={report}></ReportTable>
                </div>

        )
        }
}

export default Report;