import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';


function AlertDismissible(props) {
    // const [show, setShow] = useState(true);
    const [show] = useState(true);

  
    return (
      <>
        <Alert show={show} variant={props.variant}>
          <Alert.Heading>{props.header}</Alert.Heading>
          <p>
            {props.message}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => window.location.reload(false) } variant={props.variant}>
              Close me!
            </Button>
          </div>
        </Alert>
  
        {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}
      </>
    );
  }

  export default AlertDismissible;