import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
// import Alert from 'react-bootstrap/Alert'
// import Search from './assets/search-icon.png'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import 'bootstrap/dist/css/bootstrap.min.css';

// import Accounts from './components/Accounts';
import Header from './components/Header';
import AccessMgmt from './components/AccessMgmt';
import UserMgmt from './components/UserMgmt';
import AcctMgmt from './components/AcctMgmt';
import Report from './components/Report';


// import Account from './components/Account';
import ErrorBoundary from './components/ErrorBoundary'
import './App.css';
import '@aws-amplify/ui/dist/style.css';

import Amplify, { Auth, Hub, API } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import amplifyConfig from './amplifyConfig'

Amplify.configure(amplifyConfig)

class App extends Component {

  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    // this.getAdmin = this.getAdmin.bind(this);

    // let the Hub module listen on Auth events
    Hub.listen('auth', data => {
        switch (data.payload.event) {
            case 'signIn':
                this.setState({authState: 'signedIn', authData: data.payload.data});
                this.getuserinfo()
                break;
            case 'signIn_failure':
                this.setState({authState: 'signIn', authData: null, authError: data.payload.data});
                break;
            default:
                break;
        }
    });
    this.state = {
      authState: 'loading',
      authData: null,
      authError: null,
      user: null,
      email: null,
      // accounts: [],
      accounts_group: [],
      users_group: [],
      getAccountStatus: null
      // searchText: ""
    }
  }

  componentDidMount() {
    this.getuserinfo()
  }

  getuserinfo = () => {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({user : user});
      this.setState({email : user.attributes.email});
      this.setState({authState: 'signedIn'});
      this.getAdmin()
    }).catch(e => {
      setTimeout(() => {
        if (this.state.authState === 'loading') {
          this.setState({authState: 'signIn'});
        }
      }, 500)
    });
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn'});
    }).catch(e => {
      console.log(e);
    });
  }

  async getAdmin () {
    let apiName = 'awstvm';
    let path = '/dev/admin';
    await API.get(apiName, path).then(
      response => {
        this.setState({accounts_group : response[0]});
        this.setState({users_group : response[1]});
        this.setState({getAccountStatus: 200});
        // console.log(this.state.accounts_group[0])
        // console.log(this.state.users_group[0])

        if(this.state.accounts_group === null)
          this.setState({accounts_group : []});
        if(this.state.users_group === null)
          this.setState({users_group : []});
    }
    )
    .catch(error => {
        if(error)
          if(error.response)
            if(error.response.status)
              this.setState({getAccountStatus: error.response.status});
              console.log(error.response)
        }
      )
  }

  // handleSearchInput = event => {
  //   this.setState({
  //     searchText: event.target.value
  //   });
  // };

  render() {
    const { authState, email, accounts_group, users_group } = this.state;

    return (
      <div className="App">
        {authState === 'loading' &&
        (<div>
          loading...
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>)
        }

        {authState === 'signIn' && this.props.OAuthSignIn({customProvider: 'SiemensMyID'})}
        {authState === 'signedIn' &&
          <Container>
              <Header user={email} signOut={this.signOut} />
            {/* <ErrorBoundary>
              <Form inline>
              <FormControl
                onChange={this.handleSearchInput}
                value={searchText}
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                // width=90%
              />
              <Form.Label><img src={Search} alt=""></img></Form.Label>
              </Form>
            </ErrorBoundary> */}

            <ErrorBoundary >
            <Tabs defaultActiveKey="Access Management" transition={false} id="noanim-tab-example">
              <Tab eventKey="Access Management" title="Access Management">
                <AccessMgmt accounts_group={accounts_group} users_group={users_group} ></AccessMgmt>
              </Tab>
              <Tab eventKey="User Management" title="User Management">
                <UserMgmt users_group={users_group} ></UserMgmt>
              </Tab>
              <Tab eventKey="Account Management" title="Account Management">
                <AcctMgmt accounts_group={accounts_group} ></AcctMgmt>
              </Tab>
              <Tab eventKey="Reports" title="Reports">
                <Report accounts_group={accounts_group} users_group={users_group} ></Report>
                {/* <ReportTable></ReportTable> */}
              </Tab>
            </Tabs>
            
            </ErrorBoundary>

          </Container>

        }
      </div>
    );
  }
}

export default withOAuth(App)
