import { Auth } from 'aws-amplify';

// Amplify.configure({
const config = {
    Auth: {
        
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_F231s3NJw',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '4nrq3kq1soiun53sbvgts9u6vk',
        oauth : {
          domain: 'copstvm.auth.eu-central-1.amazoncognito.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://cam-dev.mindsphere-tools.siemens.cloud',
          redirectSignOut: 'https://cam-dev.mindsphere-tools.siemens.cloud',
          // redirectSignIn: 'http://localhost:3000',
          // redirectSignOut: 'http://localhost:3000',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    API: {
        endpoints: [
            {
                name: "awstvm",
                endpoint: " https://qi09t1t4s6.execute-api.eu-central-1.amazonaws.com", //DEV
                // endpoint: "https://g5hvp3tedd.execute-api.us-east-1.amazonaws.com", //QA
                custom_header: async () => {
                  return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
  };

export default config
